import * as React from "react";
import type { HeadFC } from "gatsby";
import { CustomHead } from "../components/shell/CustomHead";
import Shell from "../components/shell/Shell";
import ImageHero from "../components/shared/ImageHero";
import TermsArticle from "../components/articles/terms";

const pageDescription =
  "We know it's tempting to skip these Terms of Service, but it's important to establish what you can expect from us as you use our services, and what we expect from you.";
const pagesBreadcrumb = [
  {
    name: "Terms",
    to: "/terms",
  },
];

const TermsPage = () => {
  return (
    <Shell>
      <ImageHero
        title="Our usage agreement with you"
        description={pageDescription}
        pages={pagesBreadcrumb}
      />
      <TermsArticle />
    </Shell>
  );
};

export default TermsPage;

export const Head: HeadFC = () => (
  <CustomHead title="Terms of Service" description={pageDescription} />
);
